import { ValueDisplay } from "./ValueDisplay";

export const enum SchoolTypeId {
  HIGH_SCHOOL = 1,
  JUNIOR_COLLEGE = 2,
  FOUR_YEAR_COLLEGE = 3,
  NO_SCHOOL = 4,
}

export enum SchoolTypeName {
  HIGH_SCHOOL = "High School",
  JUNIOR_COLLEGE = "Junior College",
  FOUR_YEAR_COLLEGE = "4-Year College",
  HOME_SCHOOL = "Homeschool",
}

export type SchoolTypeNameType =
  | SchoolTypeName.HIGH_SCHOOL
  | SchoolTypeName.JUNIOR_COLLEGE
  | SchoolTypeName.FOUR_YEAR_COLLEGE
  | SchoolTypeName.HOME_SCHOOL;

export type School = {
  id: number;
  name: string;
  city: string;
  stateProvince: string;
  country: string;
  schoolTypeId: number;
  schoolTypeName: SchoolTypeNameType;
};

export interface SchoolOption extends ValueDisplay {
  value: School;
}

export type AcademicClass = {
  [SchoolTypeName.HIGH_SCHOOL]: string[];
  [SchoolTypeName.JUNIOR_COLLEGE]: string[];
  [SchoolTypeName.FOUR_YEAR_COLLEGE]: string[];
};

export const SchoolAttendingStatus = {
  ATTENDING: 1,
  VERBAL_COMMIT: 2,
  SIGNED_NLI: 3,
  GRADUATED: 4,
  ATTENDED: 5,
};

export type AcademicClassDTO = {
  academicClassId: number;
  academicClass: string;
  type: SchoolTypeName[];
};

export const AcademicClasses: AcademicClassDTO[] = [
  {
    academicClassId: 1,
    academicClass: "Freshman",
    type: [SchoolTypeName.FOUR_YEAR_COLLEGE, SchoolTypeName.HIGH_SCHOOL, SchoolTypeName.HOME_SCHOOL],
  },
  {
    academicClassId: 2,
    academicClass: "Sophomore",
    type: [SchoolTypeName.FOUR_YEAR_COLLEGE, SchoolTypeName.HIGH_SCHOOL, SchoolTypeName.HOME_SCHOOL],
  },
  {
    academicClassId: 3,
    academicClass: "Junior",
    type: [SchoolTypeName.FOUR_YEAR_COLLEGE, SchoolTypeName.HIGH_SCHOOL, SchoolTypeName.HOME_SCHOOL],
  },
  {
    academicClassId: 4,
    academicClass: "Senior",
    type: [SchoolTypeName.FOUR_YEAR_COLLEGE, SchoolTypeName.HIGH_SCHOOL, SchoolTypeName.HOME_SCHOOL],
  },
  {
    academicClassId: 5,
    academicClass: "5th Year Senior",
    type: [SchoolTypeName.FOUR_YEAR_COLLEGE],
  },
  {
    academicClassId: 6,
    academicClass: "Junior College 1",
    type: [SchoolTypeName.JUNIOR_COLLEGE],
  },
  {
    academicClassId: 7,
    academicClass: "Junior College 2",
    type: [SchoolTypeName.JUNIOR_COLLEGE],
  },
  {
    academicClassId: 8,
    academicClass: "Junior College 3",
    type: [SchoolTypeName.JUNIOR_COLLEGE],
  },
  {
    academicClassId: 9,
    academicClass: "Graduate Student",
    type: [SchoolTypeName.FOUR_YEAR_COLLEGE],
  },
];

export const getAcademicClasses = (type: SchoolTypeName): AcademicClassDTO[] =>
  AcademicClasses.filter((value: AcademicClassDTO) => value.type.includes(type));

export type ReturningProspectsSchoolHistoryResponseDTO = {
  previousSchoolAttendingStatusId: number | null;
  currentAcademicClassId: number | null;
  ebisSchoolId: number | null;
  startDate: Date | null;
  endDate: Date | null;
  hasShirted: boolean | null;
  schoolClassDifferent: boolean | null;
  ninthGradeYear: number | null;
};
